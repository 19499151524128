import { toast } from "react-hot-toast"

export const errorAlert = (text) => {
    toast.error(text, {
        duration: 4000,
        position: 'bottom-center',
    })
}

export const successAlert = (text, state) => {
    toast.success(text, {
        duration: 4000,
        position: 'bottom-center',
    })
}

