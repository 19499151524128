import React from 'react'
import { Container, Box, Grid, Typography, Button, IconButton, } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

const Lottery = () => {
    const wallet = useWallet()
    const { connected } = wallet;
    // console.log(wallet, "wallet")
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };
    return (
        <>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Box className="lottery-banner" sx={{ py: { md: '50px', xs: '20px' }, }}>
                    <Container fixed>
                        <Box sx={containerStyle}>
                            <Grid
                                container
                                spacing={5}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                            >
                                <Grid item xl={6} md={6} xs={12}>
                                    <Box sx={{ position: 'relative', zIndex: 50 }}>
                                        <Box sx={absoluteCard}>
                                            <Typography variant="body1" color="#fff">
                                                Auction ends in
                                            </Typography>
                                            <Box display="flex" gap="20px">
                                                <Box>
                                                    <Typography variant="h3" color="#fff">
                                                        59
                                                    </Typography>
                                                    <Typography variant="body1" color="#fff">
                                                        Hours
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h3" color="#fff">
                                                    :
                                                </Typography>
                                                <Box>
                                                    <Typography variant="h3" color="#fff">
                                                        40
                                                    </Typography>
                                                    <Typography variant="body1" color="#fff">
                                                        Minutes
                                                    </Typography>
                                                </Box>
                                                <Typography variant="h3" color="#fff">
                                                    :
                                                </Typography>
                                                <Box>
                                                    <Typography variant="h3" color="#fff">
                                                        36
                                                    </Typography>
                                                    <Typography variant="body1" color="#fff">
                                                        Seconds
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <img src={require("../assets/app-skull/ASSRadioguy.jpg")} alt="img" style={{ borderRadius: '10px', width: '100%' }} />
                                    </Box>
                                </Grid>
                                <Grid item xl={6} md={6} xs={12}>
                                    <Typography variant="h2" color="initial" sx={{ WebkitTextFillColor: "#27A410" }}>
                                        Lottery #10
                                    </Typography>
                                    <Typography variant="body1" color="#fff">
                                        Ticket Entry For ASS Lottery Prize Draw #10, Giving The Holder A Chance To Win:
                                    </Typography>
                                    <Typography variant="body1" color="#fff">
                                        <ul>
                                            {new Array(3).fill('').map((item, i) =>
                                                <li key={i}>Point {i + 1}  </li>
                                            )}
                                        </ul>
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: { md: 'nowrap', xs: 'wrap' }, gap: '20px' }}>
                                        <Box sx={{ background: "#000", p: '20px 50px', borderRadius: '10px' }}>
                                            <Typography variant="h6" color="#fff">
                                                Tickets<br />
                                                217
                                            </Typography>
                                        </Box>
                                        <Box sx={{ background: "#000", p: '20px 50px', borderRadius: '10px' }}>
                                            <Typography variant="h6" color="#fff">
                                                Price<br />
                                                0.056 SOL
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ position: 'relative', zIndex: 20 }}>
                                        {/* sx={{ position: 'relative', zIndex: 50 }} */}
                                        {connected ?
                                            <Button size="large" fullWidth variant="contained" color="primary" sx={{ color: '#fff', my: '10px' }}>
                                                Buy Tickets
                                            </Button>
                                            :
                                            <Box sx={{ background: '#27A410', borderRadius: '8px', textAlign: 'center', my: '10px' }}>
                                                <WalletMultiButton style={{ width: '100%', background: '#27A410', whiteSpace: 'nowrap', }} />
                                            </Box>
                                        }
                                        <Typography onClick={handleClickOpen} variant="body2" sx={{ textDecoration: 'underline', cursor: 'pointer' }} textAlign="center" color="#fff">
                                            How to play ?
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box >
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        background: "rgba(29, 29, 29, 0.33)",
                        backdropFilter: "blur(5px)",
                        WebkitBackdropFilter: "blur(0px) saturate(180%)",
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                    },
                }}
            >
                <Box sx={{ position: 'relative' }}>
                    <IconButton aria-label="" onClick={handleClose} sx={{
                        position: 'absolute', right: 8,
                        top: 8,
                    }} size='small'>
                        <CloseIcon color="primary" fontSize="small" />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title" sx={{ WebkitTextFillColor: "#27A410" }}>
                        How to play ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ color: '#fff' }}>
                            You pay 0.056 solana, buy a ticket to join the current round of lottery. The Player with the winning ticket for this round gets the current round lottery fund. The prize will be distributed to the winner's address. In case of no winner for this round, the prize money will roll over to the next round. A fixed commission is charged for every purchase.
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>
        </>
    )
}

export default Lottery

const bg = {
    background: "rgba(29, 29, 29, 0.33)",
    backdropFilter: "blur(5px)",
    WebkitBackdropFilter: "blur(0px) saturate(180%)",
}

const containerStyle = {
    ...bg,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    p: { md: '50px', xs: '20px' },
    borderRadius: '10px',
}

const absoluteCard = {
    ...bg, display: 'inline-block',
    p: '30px',
    borderRadius: '10px',
    position: 'absolute',
    left: { md: 'unset', xs: "50%" },
    transform: { md: 'unset', xs: "translate(-50%, -50%)" },
    top: { md: '20px', xs: '50%' },
    right: { md: '20px', xs: 'auto' },
    boxSizing: 'border-box'
}