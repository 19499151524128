import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#27A410",
            dark: "#27A410",
        },
        secondary: {
            main: "#fff",
            light: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            // lg: 1300,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: "adineue",
        h1: {
            fontFamily: "adineue",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "80px",
            lineHeight: "119.3%",
            textAlign: "center",
            color: "#D5A04B"
        },
        h2: {
            fontFamily: "adineue",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "64px",
            lineHeight: "119.3%",
            color: "#D5A04B"
        },
        h3: {
            fontFamily: "adineue",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "30px",
            lineHeight: "145.99%",
            color: "#fff"
        },
        h4: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.938rem",
            // 31px
            lineHeight: "120%",
        },
        h5: {
            fontFamily: "'adineue'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "35px",
            color: "#fff"
        },
        h6: {
            fontFamily: "'adineue'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "125.99%",
            // letterSpacing: "1.115em",
            color: "#FFFFFF"
        },
        body1: {
            fontFamily: "adineue",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "150.99%",
            color: "#919191"
        },
        body2: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "0.900rem",
            // 13px
            lineHeight: "120%",
        },
        subtitle1: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "0.625rem",
            // 10px
            lineHeight: "120%",
        },
        subtitle2: {},
    },
});


const theme = {
    ...defaultTheme,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                    borderRadius: '8px',
                    // cursor: new url("../thundercursor.png"),
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "60px",
                    },
                },
                h2: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "50px",
                    },
                },
                h3: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "30px",
                    },
                },
                h5: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "24px",
                    },
                },
                h6: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "20px",
                    },
                },
                body1: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: "16px",
                    },
                },
            },
        },
    },

};

export default theme;
