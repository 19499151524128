import { Program, web3 } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import {
  AccountInfo,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { programs } from "@metaplex/js";

// import fs from 'fs';
import { GlobalPool, UserPool } from "./types";
import { IDL } from "./mutable_staking";
import {
  solConnection,
  PROGRAM_ID,
  REWARD_TOKEN_MINT,
  USER_POOL_SIZE,
  GLOBAL_AUTHORITY_SEED,
  METAPLEX,
  REWARD_TOKEN_DECIMAL,
  EPOCH_TIER,
  TIER_REWARD,
} from "../config";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { successAlert } from "../../components/alerts/toastGroup";
// import { successAlert } from "../components/toastGroup";

export const getNftMetaData = async (nftMintPk: PublicKey) => {
  let {
    metadata: { Metadata },
  } = programs;
  let metadataAccount = await Metadata.getPDA(nftMintPk);
  const metadat = await Metadata.load(solConnection, metadataAccount);
  return metadat;
};

export const calculateRewards = async (userAddress: PublicKey) => {
  if (!userAddress) return 0;

  const userPool: UserPool | null = await getUserPoolState(userAddress);
  if (userPool === null) return 0;

  let userPoolInfo = {
    ...userPool,
    owner: userPool.owner.toBase58(),
    lastRewardTime: userPool.lastRewardTime.toNumber(),
    pendingReward: userPool.pendingReward.toNumber(),
    stakedCount: userPool.stakedCount.toNumber(),
    stakedNfts: userPool.stakedNfts
      .slice(0, userPool.stakedCount.toNumber())
      .map((info) => {
        return {
          mint: info.mint.toBase58(),
          tier: info.tier.toNumber(),
          stakedTime: info.stakedTime.toNumber(),
        };
      }),
  };
  // console.log(userPoolInfo);

  let now = Math.floor(Date.now() / 1000);
  // console.log("Now=", now, "LastRewardTime=", userPoolInfo.lastRewardTime);
  let total_reward = 0;

  for (let i = 0; i < userPoolInfo.stakedCount; i++) {
    const tier = userPoolInfo.stakedNfts[i].tier;
    console.log(tier, "tier");
    let rewardTime =
      userPoolInfo.stakedNfts[i].stakedTime + EPOCH_TIER[tier - 1];
    if (rewardTime < userPoolInfo.lastRewardTime) continue;

    if (now > rewardTime) total_reward += TIER_REWARD[tier - 1];
  }

  total_reward += userPoolInfo.pendingReward;

  // console.log("Current reward=", total_reward);
  return total_reward / REWARD_TOKEN_DECIMAL;
};

export const initUserPool = async (wallet: WalletContextState) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;
  let cloneWindow: any = window;

  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  let userPoolKey = await PublicKey.createWithSeed(
    userAddress,
    "user-pool",
    program.programId
  );

  console.log(userAddress.toBase58() + "," + userPoolKey.toBase58());
  console.log(USER_POOL_SIZE);
  let ix = SystemProgram.createAccountWithSeed({
    fromPubkey: userAddress,
    basePubkey: userAddress,
    seed: "user-pool",
    newAccountPubkey: userPoolKey,
    lamports: await solConnection.getMinimumBalanceForRentExemption(
      USER_POOL_SIZE
    ),
    space: USER_POOL_SIZE,
    programId: program.programId,
  });

  let tx = new Transaction({
    recentBlockhash: await (await solConnection.getLatestBlockhash()).blockhash,
    feePayer: wallet.publicKey,
  });
  console.log("getLatestBlockhash added");
  tx.add(ix);
  tx.add(
    program.instruction.initializeUserPool({
      accounts: {
        userPool: userPoolKey,
        owner: userAddress,
      },
      instructions: [
        // ix
      ],
      signers: [],
    })
  );
  const txId = await wallet.sendTransaction(tx, solConnection);
  await solConnection.confirmTransaction(txId, "finalized");
  console.log("Your transaction signature", tx);
  let poolAccount = await program.account.userPool.fetch(userPoolKey);
  console.log("Owner of initialized pool = ", poolAccount.owner.toBase58());
  successAlert("Init user pool has been successful!");
};

export const stakeNft = async (
  wallet: WalletContextState,
  mint: PublicKey,
  tier: number
) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;
  let cloneWindow: any = window;

  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);
  console.log(program.programId.toBase58());
  let userTokenAccount = await getAssociatedTokenAccount(userAddress, mint);
  let accountOfNFT = await getNFTTokenAccount(mint);
  if (userTokenAccount.toBase58() != accountOfNFT.toBase58()) {
    let nftOwner = await getOwnerOfNFT(mint);
    if (nftOwner.toBase58() == userAddress.toBase58())
      userTokenAccount = accountOfNFT;
    else {
      console.log("Error: Nft is not owned by user");
      return;
    }
  }
  console.log("NFT = ", mint.toBase58(), userTokenAccount.toBase58());

  const [globalAuthority, bump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  let { instructions, destinationAccounts } = await getATokenAccountsNeedCreate(
    solConnection,
    userAddress,
    globalAuthority,
    [mint]
  );

  console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());
  let userPoolKey = await PublicKey.createWithSeed(
    userAddress,
    "user-pool",
    program.programId
  );

  let poolAccount = await solConnection.getAccountInfo(userPoolKey);
  if (poolAccount === null || poolAccount.data === null) {
    await initUserPool(wallet);
  }

  const metadata = await getMetadata(mint);
  console.log("Metadata=", metadata.toBase58());

  let tx = new Transaction({
    recentBlockhash: await (await solConnection.getLatestBlockhash()).blockhash,
    feePayer: wallet.publicKey,
  });

  console.log("getLatestBlockhash added");
  if (instructions.length > 0) tx.add(instructions[0]);
  tx.add(
    program.instruction.stakeNftToPool(bump, new anchor.BN(tier), {
      accounts: {
        owner: userAddress,
        userPool: userPoolKey,
        globalAuthority,
        userTokenAccount,
        destNftTokenAccount: destinationAccounts[0],
        nftMint: mint,
        mintMetadata: metadata,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenMetadataProgram: METAPLEX,
      },
      instructions: [
        // ...instructions,
      ],
      signers: [],
    })
  );
  const txId = await wallet.sendTransaction(tx, solConnection);
  await solConnection.confirmTransaction(txId, "finalized");
  successAlert("Staking has been successful!", "");
};

export const withdrawNft = async (wallet: WalletContextState, mint: any) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;

  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  let tx = new Transaction({
    recentBlockhash: await (await solConnection.getLatestBlockhash()).blockhash,
    feePayer: wallet.publicKey,
  });
  //for loop on mint[] starts
  for (let index = 0; index < mint.length; index++) {
    const mintId = mint[index];

    let ret = await getATokenAccountsNeedCreate(
      solConnection,
      userAddress,
      userAddress,
      [mintId]
    );
    let userTokenAccount = ret.destinationAccounts[0];
    console.log("Racer NFT = ", mintId.toBase58(), userTokenAccount.toBase58());

    const [globalAuthority, bump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );

    let rewardVault = await getAssociatedTokenAccount(
      globalAuthority,
      REWARD_TOKEN_MINT
    );

    let { instructions, destinationAccounts } =
      await getATokenAccountsNeedCreate(
        solConnection,
        userAddress,
        globalAuthority,
        [mintId]
      );

    console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());

    // let user_reward_act_arr = await getATokenAccountsNeedCreate(
    //     solConnection,
    //     userAddress,
    //     userAddress,
    //     [REWARD_TOKEN_MINT]
    // );
    // let user_reward_act = user_reward_act_arr.destinationAccounts[0];

    let user_reward_act = await getUserAssociatedTokenAccount(
      solConnection,
      userAddress,
      userAddress,
      REWARD_TOKEN_MINT
    );

    console.log("user reward act : " + user_reward_act.toBase58());
    let userPoolKey = await PublicKey.createWithSeed(
      userAddress,
      "user-pool",
      program.programId
    );

    if (ret.instructions.length > 0) tx.add(ret.instructions[0]);
    tx.add(
      program.instruction.withdrawNftFromPool(bump, {
        accounts: {
          owner: userAddress,
          userPool: userPoolKey,
          globalAuthority,
          userTokenAccount,
          destNftTokenAccount: destinationAccounts[0],
          rewardVault,
          userRewardAccount: user_reward_act,
          nftMint: mintId,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
        instructions: [
          // ...ret.instructions,
        ],
        signers: [],
      })
    );
  }
  //for loop on mint[] ends
  let txId = await wallet.sendTransaction(tx, solConnection);
  await solConnection.confirmTransaction(txId, "finalized");
  successAlert("Withdrawal has been successful!", "");
};

export const claimNft = async (wallet: WalletContextState, mint: PublicKey) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;

  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  let ret = await getATokenAccountsNeedCreate(
    solConnection,
    userAddress,
    userAddress,
    [mint]
  );
  let userTokenAccount = ret.destinationAccounts[0];
  console.log("Racer NFT = ", mint.toBase58(), userTokenAccount.toBase58());

  const [globalAuthority, bump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  let rewardVault = await getAssociatedTokenAccount(
    globalAuthority,
    REWARD_TOKEN_MINT
  );

  let { instructions, destinationAccounts } = await getATokenAccountsNeedCreate(
    solConnection,
    userAddress,
    globalAuthority,
    [mint]
  );

  console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());

  // let user_reward_act_arr = await getATokenAccountsNeedCreate(
  //     solConnection,
  //     userAddress,
  //     userAddress,
  //     [REWARD_TOKEN_MINT]
  // );
  // let user_reward_act = user_reward_act_arr.destinationAccounts[0];

  let user_reward_act = await getUserAssociatedTokenAccount(
    solConnection,
    userAddress,
    userAddress,
    REWARD_TOKEN_MINT
  );

  console.log("user reward act : " + user_reward_act.toBase58());
  let userPoolKey = await PublicKey.createWithSeed(
    userAddress,
    "user-pool",
    program.programId
  );

  let tx = new Transaction();
  if (ret.instructions.length > 0) tx.add(ret.instructions[0]);
  tx.add(
    program.instruction.claimNftFromPool(bump, {
      accounts: {
        owner: userAddress,
        userPool: userPoolKey,
        globalAuthority,
        userTokenAccount,
        destNftTokenAccount: destinationAccounts[0],
        rewardVault,
        userRewardAccount: user_reward_act,
        nftMint: mint,
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      instructions: [
        // ...ret.instructions,
      ],
      signers: [],
    })
  );
  let txId = await wallet.sendTransaction(tx, solConnection);
  await solConnection.confirmTransaction(txId, "finalized");
  successAlert("Withdrawal has been successful!", "");
};

export const claimReward = async (wallet: WalletContextState) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;

  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  const [globalAuthority, bump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );

  console.log("globalAuthority =", globalAuthority.toBase58());
  let rewardVault = await getAssociatedTokenAccount(
    globalAuthority,
    REWARD_TOKEN_MINT
  );
  console.log(rewardVault?.toString(), "rewardVault");
  let userPoolKey = await PublicKey.createWithSeed(
    userAddress,
    "user-pool",
    program.programId
  );

  let { instructions, destinationAccounts } = await getATokenAccountsNeedCreate(
    solConnection,
    userAddress,
    userAddress,
    [REWARD_TOKEN_MINT]
  );

  console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());
  // console.log(await solConnection.getTokenAccountBalance(destinationAccounts[0]));

  let tx = new Transaction();
  if (instructions.length > 0) tx.add(instructions[0]);
  tx.add(
    program.instruction.claimReward(bump, {
      accounts: {
        owner: userAddress,
        userPool: userPoolKey,
        globalAuthority,
        rewardVault,
        userRewardAccount: destinationAccounts[0],
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      instructions: [
        // ...instructions,
      ],
      signers: [],
    })
  );

  const txId = await wallet.sendTransaction(tx, solConnection);
  console.log("Your transaction signature", txId);
  await solConnection.confirmTransaction(txId, "finalized");
  successAlert("Claim has been successful!", "");

  console.log(
    await solConnection.getTokenAccountBalance(destinationAccounts[0])
  );
};

export const getGlobalState = async (): Promise<GlobalPool | null> => {
  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  const [globalAuthority, bump] = await PublicKey.findProgramAddress(
    [Buffer.from(GLOBAL_AUTHORITY_SEED)],
    program.programId
  );
  try {
    let globalState = await program.account.globalPool.fetch(globalAuthority);
    return globalState as GlobalPool;
  } catch {
    return null;
  }
};

export const getUserPoolState = async (
  userAddress: PublicKey
): Promise<UserPool | null> => {
  if (!userAddress) return null;
  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  let userPoolKey = await PublicKey.createWithSeed(
    userAddress,
    "user-pool",
    program.programId
  );
  // console.log('User Pool: ', userPoolKey.toBase58());
  try {
    let poolState = await program.account.userPool.fetch(userPoolKey);
    return poolState as UserPool;
  } catch {
    return null;
  }
};

const getOwnerOfNFT = async (nftMintPk: PublicKey): Promise<PublicKey> => {
  let tokenAccountPK = await getNFTTokenAccount(nftMintPk);
  let tokenAccountInfo = await solConnection.getAccountInfo(tokenAccountPK);

  console.log("nftMintPk=", nftMintPk.toBase58());
  console.log("tokenAccountInfo =", tokenAccountInfo);

  if (tokenAccountInfo && tokenAccountInfo.data) {
    let ownerPubkey = new PublicKey(tokenAccountInfo.data.slice(32, 64));
    console.log("ownerPubkey=", ownerPubkey.toBase58());
    return ownerPubkey;
  }
  return new PublicKey("");
};

const getNFTTokenAccount = async (nftMintPk: PublicKey): Promise<PublicKey> => {
  console.log("getNFTTokenAccount nftMintPk=", nftMintPk.toBase58());
  let tokenAccount = await solConnection.getProgramAccounts(TOKEN_PROGRAM_ID, {
    filters: [
      {
        dataSize: 165,
      },
      {
        memcmp: {
          offset: 64,
          bytes: "2",
        },
      },
      {
        memcmp: {
          offset: 0,
          bytes: nftMintPk.toBase58(),
        },
      },
    ],
  });
  return tokenAccount[0].pubkey;
};

const getAssociatedTokenAccount = async (
  ownerPubkey: PublicKey,
  mintPk: PublicKey
): Promise<PublicKey> => {
  let associatedTokenAccountPubkey = (
    await PublicKey.findProgramAddress(
      [
        ownerPubkey.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        mintPk.toBuffer(), // mint address
      ],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];
  return associatedTokenAccountPubkey;
};

const getATokenAccountsNeedCreate = async (
  connection: anchor.web3.Connection,
  walletAddress: anchor.web3.PublicKey,
  owner: anchor.web3.PublicKey,
  nfts: anchor.web3.PublicKey[]
) => {
  let instructions = [],
    destinationAccounts = [];
  for (const mint of nfts) {
    const destinationPubkey = await getAssociatedTokenAccount(owner, mint);
    const response = await connection.getAccountInfo(destinationPubkey);
    if (!response) {
      const createATAIx = createAssociatedTokenAccountInstruction(
        destinationPubkey,
        walletAddress,
        owner,
        mint
      );
      instructions.push(createATAIx);
    }
    destinationAccounts.push(destinationPubkey);
  }
  return {
    instructions,
    destinationAccounts,
  };
};

const getUserAssociatedTokenAccount = async (
  connection: anchor.web3.Connection,
  walletAddress: anchor.web3.PublicKey,
  owner: anchor.web3.PublicKey,
  mint: anchor.web3.PublicKey
) => {
  let cloneWindow: any = window;
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  let userATA = await getAssociatedTokenAccount(owner, mint);

  const userAccount = await connection.getAccountInfo(userATA);
  console.log("userAccount = " + userAccount);

  if (userAccount === null) {
    console.log("if userAccount: ");
    let newTokenAccIx = createAssociatedTokenAccountInstruction(
      userATA,
      walletAddress,
      owner,
      mint
    );
    let newTokenAccTx = new anchor.web3.Transaction().add(newTokenAccIx);

    const signature = await provider.send(newTokenAccTx);
    console.log("SIGNATURE-create", signature);

    userATA = await getAssociatedTokenAccount(owner, mint);
    console.log("new userATA: " + userATA);
  }

  return userATA;
};
const createAssociatedTokenAccountInstruction = (
  associatedTokenAddress: anchor.web3.PublicKey,
  payer: anchor.web3.PublicKey,
  walletAddress: anchor.web3.PublicKey,
  splTokenMintAddress: anchor.web3.PublicKey
) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: ASSOCIATED_TOKEN_PROGRAM_ID,
    data: Buffer.from([]),
  });
};

/** Get metaplex mint metadata account address */
const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  return (
    await PublicKey.findProgramAddress(
      [Buffer.from("metadata"), METAPLEX.toBuffer(), mint.toBuffer()],
      METAPLEX
    )
  )[0];
};

export const getRewardBalance = async (wallet: WalletContextState) => {
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;
  let cloneWindow: any = window;

  console.log("wallet address : " + userAddress);
  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);

  let rewardATA = await getAssociatedTokenAccount(
    userAddress,
    REWARD_TOKEN_MINT
  );
  const userAccount = await solConnection.getAccountInfo(rewardATA);
  console.log("userAccount = " + JSON.stringify(userAccount));
  let tokenBalance = "0";
  if (userAccount != null) {
    const objBalance = await solConnection.getTokenAccountBalance(rewardATA);
    // console.log("Reward Balance" + JSON.stringify(bal));
    tokenBalance = String(objBalance.value.uiAmountString);
  }

  return tokenBalance;
};

export const stakeMultipleNft = async (
  wallet: WalletContextState,
  mint: any,
  tier: number
) => {
  //change mint to mint[]
  if (!wallet.publicKey) return;
  let userAddress: PublicKey = wallet.publicKey;
  let cloneWindow: any = window;

  let provider = new anchor.Provider(
    solConnection,
    cloneWindow["solana"],
    anchor.Provider.defaultOptions()
  );
  const program = new anchor.Program(IDL as anchor.Idl, PROGRAM_ID, provider);
  console.log(program.programId.toBase58());

  let tx = new Transaction({
    recentBlockhash: await (await solConnection.getLatestBlockhash()).blockhash,
    feePayer: wallet.publicKey,
  });

  //for loop on mint[] starts
  for (let index = 0; index < mint.length; index++) {
    const mintId = mint[index];
    let userTokenAccount = await getAssociatedTokenAccount(userAddress, mintId);
    let accountOfNFT = await getNFTTokenAccount(mintId);
    if (userTokenAccount.toBase58() != accountOfNFT.toBase58()) {
      let nftOwner = await getOwnerOfNFT(mintId);
      if (nftOwner.toBase58() == userAddress.toBase58())
        userTokenAccount = accountOfNFT;
      else {
        console.log("Error: Nft is not owned by user");
        return;
      }
    }
    console.log("NFT = ", mintId.toBase58(), userTokenAccount.toBase58());

    const [globalAuthority, bump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );
    console.log(globalAuthority.toString(), "globalAuthority");
    let { instructions, destinationAccounts } =
      await getATokenAccountsNeedCreate(
        solConnection,
        userAddress,
        globalAuthority,
        [mintId]
      );

    console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());
    let userPoolKey = await PublicKey.createWithSeed(
      userAddress,
      "user-pool",
      program.programId
    );

    let poolAccount = await solConnection.getAccountInfo(userPoolKey);
    if (poolAccount === null || poolAccount.data === null) {
      await initUserPool(wallet);
    }

    const metadata = await getMetadata(mintId);
    console.log("Metadata=", metadata.toBase58());

    console.log("getLatestBlockhash added");
    if (instructions.length > 0) tx.add(instructions[0]);
    tx.add(
      program.instruction.stakeNftToPool(bump, new anchor.BN(tier), {
        accounts: {
          owner: userAddress,
          userPool: userPoolKey,
          globalAuthority,
          userTokenAccount,
          destNftTokenAccount: destinationAccounts[0],
          nftMint: mintId,
          mintMetadata: metadata,
          tokenProgram: TOKEN_PROGRAM_ID,
          tokenMetadataProgram: METAPLEX,
        },
        instructions: [
          // ...instructions,
        ],
        signers: [],
      })
    );
  }
  //for loop on mint[] ends

  const txId = await wallet.sendTransaction(tx, solConnection);
  await solConnection.confirmTransaction(txId, "finalized");
  successAlert("Staking has been successful!", "");
};
