import React from 'react'
import { Container, Grid, Box, Button, Typography } from '@mui/material'
import { Link as ScrollLink } from 'react-scroll'

const Footer = () => {
    return (
        <>
            <Container fixed sx={{ py: { md: '50px', xs: '50px' } }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    alignContent="stretch"
                    wrap="wrap"
                >
                    <Grid item xl={4} md={4} xs={12} sx={{ border: '1px solid #1C1C1C', }}>
                        <Box p='30px' sx={{ textAlign: { md: 'left', xs: "center" } }}>
                            <Typography variant="h5" color="#fff" >
                                <b>
                                    What is Solana Originals(SOLS)?
                                </b>
                            </Typography>
                            <Typography variant="body1" color="#fff" >
                                <span style={{ color: "#27A410" }}>Solana originals</span> embarks on a groundbreaking journey by introducing solana's first verified OG badges ecapsulated with unique Soul Bound Tokens (SBT). This unique approach trenscends traditional recognition system offering a tangible testament to commitment, loyalty, contribution and grit by the OGs of solana.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={4} md={4} xs={12} sx={{
                        border: '1px solid #1C1C1C',
                        background: "rgba(29, 29, 29, 0.33)",
                        backdropFilter: "blur(5px)",
                    }}>
                        <Box p='30px' textAlign="center">
                            <ScrollLink className="Home" to="Home" spy={true} smooth={true} duration={1000}>
                                <img src={require("../assets/app-skull/sols-logo.png")} alt="logo" className="logo" style={{ width: '50%' }} />
                            </ScrollLink>
                        </Box>
                    </Grid>
                    <Grid item xl={4} md={4} xs={12} sx={{ border: '1px solid #1C1C1C', }}>
                        <Box display="flex" flexDirection="column" justifyContent="center" sx={{ height: '100%' }}>
                            <Box p='30px' >
                                <Typography variant="h3" color="#fff" textAlign="center" >
                                    Join The Community
                                </Typography>
                                <Box display="flex" alignItems="center" mt="10px" justifyContent="center" gap="20px" >
                                    <Button size="small" onClick={() => window.open("https://twitter.com/TheSolOriginals")} >
                                        <img src={require(`../assets/app-skull/xlogo.png`)} alt="img" style={{ width: '30px' }} />
                                    </Button>
                                    <Button size="small" onClick={() => window.open("https://discord.gg/pDetkSpX")} >
                                        <img src={require(`../assets/app-skull/socials (2).png`)} alt="img" style={{ width: '30px' }} />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
            <Container fixed sx={{ py: { md: '10px', xs: '10px' } }}>
                <Typography variant="body1" textAlign="center">
                    Copyright The Solana Originals © 2024
                </Typography>
            </Container >
        </>
    )
}

export default Footer