import React from 'react'
import { Container, Box, AppBar, } from '@mui/material'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useAppDispatch } from '../app/hooks';
import { setWallet } from '../app/reducers/userSlice';
import { successAlert } from '../components/alerts/toastGroup';
import { useNavigate } from 'react-router-dom'

const LotteryNav = () => {
    const navigate = useNavigate();
    // wallet
    const wallet = useWallet();
    const { connected } = wallet
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const walletConnectedBefore = localStorage.getItem("walletConnected");
        if (wallet?.publicKey && !walletConnectedBefore) {
            dispatch(setWallet(wallet.publicKey.toString()));
            successAlert("solana wallet connected");
            localStorage.setItem("walletConnected", true);
            localStorage.setItem("walletAddress", wallet.publicKey.toString());
        } else if (!wallet?.publicKey && walletConnectedBefore) {
            dispatch(setWallet(""));
            localStorage.clear();
        }
        return () => {
            dispatch(setWallet(""));
            localStorage.clear();
        }
    }, [wallet?.publicKey, dispatch]);

    return (
        <>
            <AppBar
                position='static'
                color="secondary"
                sx={{
                    // marginTop: trigger ? "15px" : null,
                    background: "rgba(29, 29, 29, 0.33)",
                    backdropFilter: "blur(5px)",
                    transition: '1s ease-in-out',
                }}>
                <Container fixed>
                    <Box sx={{ display: 'flex', py: '10px', gap: { md: '40px', xs: '20px' }, alignItems: 'center', justifyContent: 'space-between', }}>
                        <Box sx={{ width: { md: '100px', xs: '50px' } }}>
                            <img src={require("../assets/app-skull/logo.PNG")} alt="Logo" style={{ width: '100%', cursor: 'pointer' }} onClick={() => navigate('/')} />
                        </Box>
                        {connected ?
                            <WalletMultiButton style={{ background: '#27A410', whiteSpace: 'nowrap', borderRadius: '8px',  }} />
                            // <Button variant="contained" color="primary" sx={{ color: '#fff', }}>
                            //     Disconnect Wallet
                            // </Button>
                            :
                            <WalletMultiButton style={{ background: '#27A410', whiteSpace: 'nowrap', borderRadius: '8px',  }} />
                        }
                    </Box>
                </Container>
            </AppBar>

        </>
    )
}

export default LotteryNav