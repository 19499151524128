import React, { Suspense, lazy } from 'react';
import { Box, CircularProgress } from '@mui/material';
const LazyHerosection = lazy(() => import('../components/home/Herosection'));
const LazyAboutSection = lazy(() => import('../components/home/AboutSection'));
const LazyKeyFeatures = lazy(() => import('../components/home/KeyFeauters'));
const LazyTeams = lazy(() => import('../components/home/Teams'));
const LazyCasino = lazy(() => import('../components/home/Casino'));
const LazyCommunity = lazy(() => import('../components/home/Community'));
// const LazyCounter = lazy(() => import('../components/home/Counter'));
const LazyPartners = lazy(() => import('../components/home/Partners'));

// import music from '../assets/sounds/music.mp3'
// import useSound from 'use-sound';

const Loader = () => {
    return <Box sx={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box textAlign="center">
            <img src={require("../assets/app-skull/logo.PNG")} alt="log" style={{ width: "100px" }} />
            <br /><br />
            <CircularProgress />
        </Box>
    </Box>;
};

const Home = () => {
    // const [playSound, { stop }] = useSound(music);
    // React.useEffect(() => {
    //     playSound();
    //     return () => stop()
    // }, [playSound, stop])
    return (
        <>
            <Suspense fallback={<Loader />}>
                <div id="Home">
                    <LazyHerosection />
                </div>
                {/* <div>
                    <LazyCounter />
                </div> */}
                <div id="About">
                    <LazyAboutSection />
                </div>
                {/* <div id="Partners">
                    <LazyPartners />
                </div> */}                
                <div id="Features">
                    <LazyKeyFeatures />
                </div>
                <div id="Community">
                    <LazyCommunity />
                </div>
                <div id="Team">
                    <LazyTeams />
                </div>
            </Suspense>
        </>
    )
}

export default Home