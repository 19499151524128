import React from 'react'
import { Button, Box, Typography, Skeleton, } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { errorAlert } from '../alerts/toastGroup';

const Unstaked = ({ setView, onStakeNFT, unstakedLoading, unstakedByJson }: any) => {
    return (
        <>
            <Formik
                initialValues={{ mints: [], }}
                onSubmit={async (values: any) => {
                    if (values.mints.length === 0) {
                        errorAlert("Please select a nft");
                    }
                    else {
                        // setSelectedMints(values.mints)
                        // console.log(values.mints, "Mints")
                        onStakeNFT(values.mints)
                    }
                }}
            >
                {({ values }) => (
                    <Form>
                        <Box sx={{ py: '10px', display: 'flex', gap: '15px', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body1" fontSize="20px" fontWeight="400" color="#fff">
                                ASS NFTS
                            </Typography>
                            <Box display="flex" gap="15px" flexWrap="wrap">
                                <Button variant="outlined" color="primary" onClick={() => setView("stake")}>
                                    Staked
                                </Button>
                                <Button variant="contained" color="primary" >
                                    Unstaked
                                </Button>
                            </Box>
                            {/* <Box display={values.mints.length === 0 ? "none" : "block"} textAlign="right" > */}
                            <Button type="submit" variant="contained"   >
                                Stake Now
                            </Button>
                            {/* </Box> */}
                        </Box>
                        <Box sx={{ padding: '15px', border: '1px solid #1C1C1C', borderRadius: '8px', height: 'auto' }}>
                            {unstakedLoading ?
                                <div className="dashboard-nft-container">
                                    <Box className="dashboard-nft-item">
                                        <Skeleton />
                                    </Box>
                                    <Box sx={{ display: { md: 'block', xs: 'none' } }} className="dashboard-nft-item">
                                        <Skeleton />
                                    </Box>
                                    <Box sx={{ display: { md: 'block', xs: 'none' } }} className="dashboard-nft-item">
                                        <Skeleton />
                                    </Box>
                                </div>
                                :
                                <Box >
                                    <Box display="flex" gap="15px" flexWrap="wrap">
                                        {unstakedByJson.map((nft: any, index: any) => (
                                            <Box key={index} sx={cardStyle}>
                                                <Box textAlign='center'>
                                                    < img src={nft.data.metadata.image} alt="nft" width="100%" height="100%" style={{ borderRadius: '8px' }} />
                                                </Box>
                                                <Typography margin="10px auto" textAlign='center' variant="body1" fontSize="15px" fontWeight="400" color="#fff">
                                                    {nft.data.metadata.name}
                                                </Typography>
                                                <Box display="flex" gap="10px" alignItems="center" justifyContent="flex-end">
                                                    <Typography variant="body1" fontSize="15px" fontWeight="400" color="#fff">
                                                        Select
                                                    </Typography>
                                                    <Box >
                                                        <Field type="checkbox" name="mints" value={nft.mint} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Unstaked



const cardStyle = {
    width: { md: '19%', xs: '100%' }, boxSizing: 'border-box', padding: '10px',
    background: '#1C1C1C', borderRadius: '8px', height: 'auto'
}