import React from 'react'
import theme from "./themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from './pages/Index';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import music from './assets/sounds/music.mp3'
import useSound from 'use-sound';
import PauseIcon from '@mui/icons-material/Pause';
import { Fab, Container } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { createBrowserRouter, RouterProvider, Outlet, useRouteError } from "react-router-dom";
import Lottery from './pages/Lottery';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LotteryNav from './components/LotteryNav';
import Staking from './pages/Staking';


const Layout = () => (
  <>
    <Outlet />
    <Footer />
  </>
);

const router = createBrowserRouter([{
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <>
        <Navbar />
        <Home />
      </>,
    },
    {
      path: "/lottery",
      element: <>
        <LotteryNav />
        <Lottery />
      </>,
    },
    {
      path: "/staking",
      element: <>
        <LotteryNav />
        <Staking />
      </>,
    },
  ],
  errorElement: < ErrorBoundary />
}]);

function ErrorBoundary() {
  let error = useRouteError();
  console.log(error, "error");
  return <>
    <Navbar />
    <Container fixed sx={{ py: '50px', textAlign: 'center' }}>
      <h2>Page not found</h2>
    </Container>
    <Footer />
  </>;
}

const App = () => {
  const [playSound, { stop, pause, }] = useSound(music, { loop: true });
  const [isPaused, setIsPaused] = React.useState(false)
  // console.log(rest, "rest");
  React.useEffect(() => {
    playSound();
    return () => {
      stop();
    };
  }, [playSound, stop,]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <ParallaxProvider>
          <RouterProvider router={router} />
        </ParallaxProvider>
      </ThemeProvider>
      <Fab size="small" sx={btnStyle} aria-label="add" onClick={() => {
        isPaused ? playSound() : pause(); setIsPaused(!isPaused)
      }}>
        {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
      </Fab>
    </>
  )
}

export default App

const btnStyle = {
  background: "#27A410", color: "#fff",
  "&:hover": {
    background: "#fff", color: "#27A410",
  },
  position: 'fixed',
  bottom: '20px',
  right: '20px'
}