import React from 'react'
import AppBar from '@mui/material/AppBar'
import { Box, Button, Divider, useScrollTrigger, useMediaQuery, useTheme, MenuItem, Menu, Typography, } from '@mui/material'
import Container from '@mui/material/Container'
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { Link as ScrollLink } from 'react-scroll'
// import MyPDF from '../assets/Flash-Coin-FLC-Whitepaper.pdf';
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1000));
    const trigger = useScrollTrigger();
    // menu action
    const [activeLink, setActiveLink] = React.useState("Home")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item) => {
        setAnchorEl(null);
        item && setActiveLink(item)
    };
   
    return (
        <>
            <AppBar
                position={trigger ? "static" : 'static'}
                color="secondary"
                sx={{
                    // marginTop: trigger ? "15px" : null,
                    background: "rgba(29, 29, 29, 0.33)",
                    backdropFilter: "blur(5px)",
                    transition: '1s ease-in-out',
                }}>
                <Container fixed>
                    <Box sx={{
                        display: 'flex', py: '10px', gap: { md: '40px', xs: '20px' },
                        alignItems: 'center', justifyContent: 'space-evenly',
                    }}>
                        {isMobile &&
                            <ScrollLink onClick={() => {
                                setActiveLink("Home");
                                window.location.pathname === "/lottery" && navigate('/')
                            }}
                                className={"Home"} to={"Home"} spy={true} smooth={true} duration={1000} style={{ cursor: 'pointer' }}>
                                <img src={require("../assets/app-skull/sols-logo.png")} alt="Logo" style={{ width: '50px' }} />
                            </ScrollLink>
                        }
                        {!isMobile && (<>
                            {["Home", "About", "Features",].map((item) =>
                                <ScrollLink onClick={() => {
                                    setActiveLink(item);
                                    window.location.pathname === "/lottery" && navigate('/')
                                }}
                                    key={item} className={item} to={item} spy={true} smooth={true} duration={1000} style={{ cursor: 'pointer' }}>
                                    <Typography variant="h5" color={activeLink === item ? "#27A410" : "#fff"}>
                                        {item}
                                    </Typography>
                                </ScrollLink>
                            )}
                            <ScrollLink onClick={() => {
                                setActiveLink("Home");
                                window.location.pathname === "/lottery" && navigate('/')
                            }}
                                className={"Home"} to={"Home"} spy={true} smooth={true} duration={1000} style={{ cursor: 'pointer' }}>
                                <img src={require("../assets/app-skull/sols-logo.png")} alt="Logo" style={{ width: '100px' }} />
                            </ScrollLink>
                            {["Community", "Team"].map((item) =>
                                <ScrollLink onClick={() => {
                                    setActiveLink(item);
                                    window.location.pathname === "/lottery" && navigate('/')
                                }}
                                    key={item} className={item} to={item} spy={true} smooth={true} duration={1000} style={{ cursor: 'pointer' }}>
                                    <Typography variant="h5" color={activeLink === item ? "#27A410" : "#fff"}>
                                        {item}
                                    </Typography>
                                </ScrollLink>
                            )}
                            <Typography variant="h5" color="#fff" style={{ cursor: 'pointer' }}
                                onClick={() => window.open("https://docs.thesolsoriginals.com/")}
                            >
                                Lightpaper
                            </Typography>
                            {/* <WalletMultiButton style={{ background: '#27A410', whiteSpace: 'nowrap', borderRadius: '8px', }} /> */}
                        </>)}
                        {isMobile && (<>
                            <Box flexGrow={1} />
                            {/* <IconButton size="small">
                                <MenuIcon color="primary" />
                            </IconButton> */}
                            <Button
                                size="large"
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                endIcon={<TouchAppIcon color="primary" />}
                            >
                                Menu
                            </Button>
                        </>)}
                    </Box>
                </Container >
            </AppBar >
            <Divider sx={{ background: '#1C1C1C' }} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    sx: {
                        background: "rgba(29, 29, 29, 0.33)",
                        backdropFilter: "blur(5px)",
                        WebkitBackdropFilter: "blur(0px) saturate(180%)",
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                    },
                }}
            >
                {["Home", "About", "Tools", "Team",].map((item) =>
                    <MenuItem fullWidth key={item} >
                        <Button fullWidth variant={activeLink === item ? "outlined" : "outlined"}
                            color={activeLink === item ? "primary" : "secondary"}>
                            <ScrollLink onClick={() => { handleClose(item); window.location.pathname === "/lottery" && navigate('/') }} key={item} className={item} to={item} spy={true} smooth={true} duration={1000}>
                                {item}
                            </ScrollLink>
                        </Button>
                    </MenuItem>
                )}
                <MenuItem fullWidth>
                    <Button variant="outlined" color="secondary" fullWidth
                        onClick={() => window.open("https://docs.thesolsoriginals.com/")}
                    >
                        {/* <a href={MyPDF}
                            download="Whitepaper.pdf"
                            style={{ textDecoration: 'none', color: '#D5A04B' }}
                        > */}
                        Lightpaper
                        {/* </a> */}
                    </Button>
                </MenuItem>
                {/* <MenuItem fullWidth>
                    <WalletMultiButton style={{ background: '#27A410', whiteSpace: 'nowrap', borderRadius: '8px', }} />
                </MenuItem> */}
            </Menu>
        </>
    )
}

export default Navbar