import { useWallet } from '@solana/wallet-adapter-react';
import React, { useEffect, useState } from 'react'
import { calculateRewards, claimReward, getGlobalState, getNftMetaData, getUserPoolState, stakeMultipleNft, withdrawNft, getRewardBalance } from '../utils/scripts/scripts';
import { PublicKey } from '@solana/web3.js';
import collection from '../utils/collections.json'
import { EPOCH_TIER, REWARD_TOKEN_DECIMAL, solConnection, TIER_REWARD } from '../utils/config';
import { UserPool } from '../utils/scripts/types';
import { getParsedNftAccountsByOwner } from '@nfteyez/sol-rayz';
import { errorAlert } from '../components/alerts/toastGroup';
import { Button, Container, Box, Typography, CircularProgress, Backdrop } from '@mui/material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Staked from '../components/staking/Staked';
import Unstaked from '../components/staking/Unstaked';

const Staking = () => {
    const tier = 30;
    const wallet = useWallet();
    const [veiw, setView] = React.useState("unstake")
    // 
    const [totalStakedCnt, setTotalStakedCount] = useState<number>(0);
    const [stakedCnt, setStakedCount] = useState<number>(0);
    // const [isStakingBoard, setBoard] = useState<boolean>(true);

    const [stakedLoading, setStakedLoading] = useState<boolean>(false)
    const [unstakedLoading, setUnStakedLoading] = useState<boolean>(false)
    const [processing, setProcessing] = useState<string>('');
    // const [stakingMint, setStakingMint] = useState<string>('');
    const [lastClaimedTime, setLastClaimedTime] = useState<number>(0);

    const [userStakedNFTs, setUserStakedNFTs] = useState<any[]>([]);
    // const [userStakedNFTsByJson, setUserStakedNFTsByJson] = useState<any[]>([]);
    const [unstaked, setUnstaked] = useState<any[]>([]);
    const [unstakedByJson, setUnstakedByJson] = useState<any[]>([]);
    const [rewardAmount, setRewardAmount] = useState<number>(0);
    const [walletReward, setWalletReward] = useState<String | undefined>("0");
    // const [selectedMints, setSelectedMints] = useState<any>(null);


    // Redirect landing if wallet disconnected
    useEffect(() => {
        if (!wallet.connected) {
            setRewardAmount(0);
            setUserStakedNFTs([]);
            setUnstaked([]);
            refreshTotalStaked();
            return;
        }

        getUnstakedNFTs();
        getStakedNFTs();
        refreshRewards();
        // refreshTotalStaked();
        GetWalletReward();
        const itv = setInterval(() => {
            refreshRewards();
            refreshTotalStaked();
        }, 2000);

        return (() => {
            clearInterval(itv);
            setWalletReward("0");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet.connected]);

    const GetWalletReward = async () => {
        let reward = await getRewardBalance(wallet);
        setWalletReward(reward);
        // console.log("Rewards=>", typeof reward);
    }
    const getUnstakedNFTs = async () => {
        setUnStakedLoading(true);
        const unstakedNftList = await getMetadataDetail();
        // console.log(unstakedNftList, "unstakedNftList")
        const commonNfts = collection.filter((item: any) => unstakedNftList.some((data: any) => item.mint === data.mint));
        // const walletXara = unstakedNftList.filter((item) => item.data.symbol === "XARA")
        // const walletXaraMint = walletXara.map((item) => item.mint)
        // console.log(walletXara, "walletXara")
        // console.log(walletXaraMint, "walletXaraMint")
        setUnstakedByJson(commonNfts);
        setUnStakedLoading(false);
    }

    const getStakedNFTs = async () => {
        if (!wallet.publicKey) return;
        setStakedLoading(true);
        const list = await getUserPoolState(wallet.publicKey);

        let nftDump: any[] = [], count: number = 0, nftCount: number = 0;
        console.clear()
        if (list !== null) {
            for (let i = 0; i < list.stakedCount.toNumber(); i++) {
                nftCount++;
                const nft = await getNftMetaData(new PublicKey(list.stakedNfts[i].mint));
                // console.log(nft.data.mint, "nft mint address")
                const commonNfts = collection.filter((item) => item?.mint === nft?.data?.mint)
                // console.log(commonNfts, "<===== this is filterd nft from json")
                const tier = list?.stakedNfts[i]?.tier?.toNumber();
                let time = list?.stakedNfts[i]?.stakedTime?.toNumber() + EPOCH_TIER[tier - 1];
                const difference = (new Date().getTime() / 1000) - list?.stakedNfts[i]?.stakedTime.toNumber()
                if (commonNfts.length !== 0) {
                    // console.log("In commong coddition")
                    nftDump.push({
                        "name": commonNfts[0]?.data?.metadata?.name,
                        "image": commonNfts[0]?.data?.metadata?.image,
                        "mint": nft?.data?.mint,
                        "tier": TIER_REWARD[tier - 1] / REWARD_TOKEN_DECIMAL,
                        "rewardTime": time,
                        "stackedDays": difference / (24 * 3600),
                        "stackedDaysReward": Math.floor((difference / (24 * 3600))) * 2,
                    })
                }
                count++;
            }
            setUserStakedNFTs(nftDump);
        } else {
            setUserStakedNFTs([]);
        }
        setStakedLoading(false);
    }

    const refreshRewards = async () => {
        // console.log("In refresh rewards")
        if (!wallet.publicKey) return;

        const userPool: UserPool | null = await getUserPoolState(wallet.publicKey);
        if (userPool === null) setLastClaimedTime(0);
        else setLastClaimedTime(userPool.lastRewardTime.toNumber());

        const rewards = await calculateRewards(wallet.publicKey);
        setRewardAmount(rewards);
        // console.log("Rewards=", typeof rewards);
    }

    const getMetadataDetail = async () => {
        if (!wallet.publicKey) return [];
        const nftsList = await getParsedNftAccountsByOwner({ publicAddress: wallet.publicKey.toBase58(), connection: solConnection });
        return nftsList;
    }

    const onStakeNFT = async (mints: any) => {
        if (!wallet.publicKey) {
            errorAlert("Invalid wallet pubkey. Please check wallet connection.");
            return;
        }
        if (processing !== '') {
            errorAlert("Please wait while processing.");
            return;
        }

        if (mints.length > 5) {
            errorAlert("Only 5 NFTs are allowed to staked at this time.");
            return;
        }
        // setStakingMint(mint);
        // setOpenDialog(true);
        setProcessing('staking');
        // console.log("tier===>", tier)
        // withPubKey is array of mints converted in format of publick key
        // console.log(mints, "mints")
        // console.log(selectedMints, "selectedMints")
        const withPubKey = mints?.map((item: any) => item = new PublicKey(item))
        console.log(withPubKey, "withpubkey")
        try {
            await stakeMultipleNft(wallet, withPubKey, tier);
            getUnstakedNFTs();
            getStakedNFTs();
        } catch (e: any) {
            errorAlert(e.message || e.msg || JSON.stringify(e));
        }
        setProcessing('');
    };

    const onUnstakeNFT = async (mints: any) => {
        if (!wallet.publicKey) {
            errorAlert("Invalid wallet pubkey. Please check wallet connection.");
            return;
        }
        if (processing !== '') {
            errorAlert("Please wait while processing.");
            return;
        }
        if (mints.length > 5) {
            errorAlert("Only 5 NFTs are allowed to unstake at this time.");
            return;
        }
        setProcessing('unstake');
        // setTimeout(async () => {
        // withPubKey is array of mints converted in format of publick key
        const withPubKey = mints.map((item: any) => item = new PublicKey(item))
        console.clear()
        console.log(withPubKey, "pubkeeyeye")
        try {
            await withdrawNft(wallet, withPubKey);
            console.log("unstaked ==>");
            getUnstakedNFTs();
            getStakedNFTs();
        } catch (e: any) {
            errorAlert(e.message || e.msg || JSON.stringify(e));
        }
        setProcessing('');
        // }, 3000)
    };

    const onClaim = async () => {
        if (!wallet.publicKey) {
            errorAlert("Invalid wallet pubkey. Please check wallet connection.");
            return;
        }
        if (processing !== '') {
            errorAlert("Please wait while processing.");
            return;
        }
        if (rewardAmount === 0) {
            errorAlert("No reward generated.");
            return;
        }
        setProcessing('claim');
        try {
            await claimReward(wallet);
            setProcessing('');
            refreshRewards();
        } catch (e: any) {
            errorAlert(e.message || e.msg || JSON.stringify(e));
        }
    };

    const refreshTotalStaked = async () => {
        getGlobalState().then((globalInfo) => {
            // console.log("globalInfo=", globalInfo);
            const count = globalInfo?.totalStakedCount;
            if (count) {
                setTotalStakedCount(count.toNumber());
            }
        }).catch((e) => { console.log(e) });
        if (wallet.publicKey) {
            getUserPoolState(wallet.publicKey).then((userInfo) => {
                // console.log("globalInfo=", globalInfo);
                const count = userInfo?.stakedCount;
                if (count) {
                    setStakedCount(count.toNumber());
                }
            }).catch((e) => { console.log(e) });
        }
    }

    // const handleStake = async () => {
    //     setOpenDialog(false);
    //     setProcessing('staking');
    //     // console.log("tier===>", tier)
    //     // withPubKey is array of mints converted in format of publick key
    //     const withPubKey = selectedMints.map((item: any) => item = new PublicKey(item))
    //     try {
    //         await stakeMultipleNft(wallet, withPubKey, tier);
    //         getUnstakedNFTs();
    //         getStakedNFTs();
    //     } catch (e: any) {
    //         errorAlert(e.message || e.msg || JSON.stringify(e));
    //     }
    //     setProcessing('');
    // }
    return (
        <>
            {/* <Header walletReward={walletReward} /> */}
            <Box sx={{ borderBottom: '1px solid #1C1C1C', borderTop: '1px solid #1C1C1C' }}>
                <Container fixed sx={{ py: '10px' }}>
                    <Box display="flex" alignItems="center" gap="30px" >
                        <Typography variant="body1" fontSize="20px" fontWeight="400" color="#fff">
                            ASS Staking
                        </Typography>
                        <Box flexGrow={1} />
                        <Typography variant="body1" fontSize="20px" fontWeight="400" color="#fff">
                            Total Staked NFT : <span style={{ color: '#27A410', }}>{totalStakedCnt}</span>
                        </Typography>
                        <Button variant="contained" onClick={onClaim}>
                            Claim
                        </Button>
                        <Button variant="outlined">
                            Balance : {walletReward} $ASS
                        </Button>
                    </Box>
                </Container >
            </Box>
            <Container fixed sx={{ py: '20px' }}>
                <br />
                {!wallet.publicKey && (<>
                    <br />
                    <Box sx={{ padding: '50px', border: '1px solid #1C1C1C', borderRadius: '8px', height: 'auto' }}>
                        <Box display="flex" sx={{ justifyContent: { md: "flex-end", xs: 'center' } }} gap="30px" alignItems="center" flexWrap="wrap">
                            <Typography variant="body1" fontSize="15px" fontWeight="400" color="#fff">
                                Please Connect Wallet
                            </Typography>
                            <WalletMultiButton style={{ background: '#27A410', whiteSpace: 'nowrap', borderRadius: '8px' }} />
                        </Box>
                    </Box>
                </>)}
                {wallet.publicKey && (<>
                    {veiw === "stake" && <Staked setView={setView} stakedLoading={stakedLoading} stakedCnt={stakedCnt} userStakedNFTs={userStakedNFTs} onUnstakeNFT={onUnstakeNFT} />}
                    {veiw === "unstake" && <Unstaked setView={setView} onStakeNFT={onStakeNFT} unstakedLoading={unstakedLoading} unstakedByJson={unstakedByJson} />}
                    {processing !== '' && (<>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <Box textAlign="center">
                                <Typography variant="h4" color="#fff">
                                    {processing === 'claim' ?
                                        'Claiming...'
                                        : processing === 'stake' ?
                                            'Processing...'
                                            // 'Staking...'
                                            :
                                            'Processing...'
                                        // 'Unstaking...'
                                    }
                                </Typography>
                                <br /><br />
                                <CircularProgress disableShrink />
                            </Box>
                        </Backdrop>
                    </>)}
                </>)}
            </Container>
        </>
    )
}

export default Staking
