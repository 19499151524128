import React, { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { SolanaMobileWalletAdapter, createDefaultAddressSelector, createDefaultAuthorizationResultCache, createDefaultWalletNotFoundHandler } from '@solana-mobile/wallet-adapter-mobile'

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export default function PhantomWrapper(props: { children: any }) {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    // const wallets = useMemo(
    //     () => [
    //         new PhantomWalletAdapter(),
    //         new SlopeWalletAdapter(),
    //         new SolflareWalletAdapter({ network }),
    //         new TorusWalletAdapter(),
    //         new LedgerWalletAdapter(),
    //         new SolletWalletAdapter({ network }),
    //         new SolletExtensionWalletAdapter({ network }),
    //     ],
    //     [network]
    // );
    const wallets = useMemo(
        () => [
            new SolanaMobileWalletAdapter({
                addressSelector: createDefaultAddressSelector(),
                appIdentity: {
                    name: 'Ape skull society',
                    uri: 'https://www.apeskullsociety.com/',
                    icon: '/public/logo.PNG',
                },
                authorizationResultCache: createDefaultAuthorizationResultCache(),
                cluster: WalletAdapterNetwork.Devnet,
                onWalletNotFound: createDefaultWalletNotFoundHandler(),
            }),
            new PhantomWalletAdapter(),
        ],
        [],
    );

    return (
        // <ConnectionProvider endpoint={clusterApiUrl(WalletAdapterNetwork.Devnet)}>
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                {props.children}
            </WalletProvider>
        </ConnectionProvider>
    );
}