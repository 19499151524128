import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'token',
    initialState: {
        token: '',
        wallet: '',
        chain: '',
        blockchain: '',
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setWallet: (state, action) => {
            state.wallet = action.payload
        },
        setChain: (state, action) => {
            state.chain = action.payload
        },
        setBlockchain: (state, action) => {
            state.blockchain = action.payload
        },
    }
})
export const { setToken, setWallet, setChain, setChainType, setBlockchain } = userSlice.actions;

export default userSlice.reducer;