import { web3 } from '@project-serum/anchor';

export const PUBLISH_NETWORK = "mainnet";

export const solConnection = new web3.Connection("https://claudelle-aqb0vs-fast-mainnet.helius-rpc.com/");

export const COLLECTION_CREATOR = "6ZZtABqB36tKunRKL7eopH7TbecM6w8Tq9rUNFZKiQFA";

export const USER_POOL_SIZE = 4864;     // 8 + 4856
export const EPOCH_TIER = [60, 300, 600, 1800];  // 86400
export const TIER_REWARD = [90_000_000, 540_000_000, 1620_000_000, 3150_000_000];
export const GLOBAL_AUTHORITY_SEED = "global-authority";

export const METAPLEX = new web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
export const REWARD_TOKEN_MINT = new web3.PublicKey("EeSSpFMqEsgjFVMWBiTXkdAiJwWNSSkkZsmHeg8nEUiM");
export const PROGRAM_ID = "HJ1CYx5p8VMzN5Nh38dnav2DAAxeYbttLB1RNi8atWPJ";

export const REWARD_TOKEN_DECIMAL = 1_000_000_000;

 